<template>
  <div class="view pa24">
    <div class="d-flex align-items-center">
<!--      <h3 class="cA1 mr10">-->
<!--        已回款<span style="color: rgb(81, 203, 205)">{{-->
<!--          moneyBack | formatMoney-->
<!--        }}</span-->
<!--        >元，待回款{{ noMoneyBack | formatMoney }}元-->
<!--      </h3>-->
<!--      <span>注：确认收货后回款才到账</span>-->
    </div>
    订单状态：
    <el-radio
      v-model="radioValue"
      class="mr10 "
      v-for="(item, index) in options"
      style="margin: 0"
      :label="item.value"
      :key="index"
      border
      size="medium"
      >{{ item.label }}</el-radio
    >
    <div style="float: left;margin-right: 10px;">
      订单编号:
      <el-input placeholder="请输入订单编号" v-model="ordersNo" style="width:230px;"></el-input>
    </div>
    <el-button type="primary" @click="selectCompanyTransferList"
      >搜索</el-button
    >
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
          prop="ordersNo"
          align="center"
          label="订单编号"
          show-overflow-tooltip
        />
        <el-table-column prop="goodsName" align="center" show-overflow-tooltip label="商品名称" />
        <el-table-column prop="orderAmount" align="center" label="付款金额（元）">
          <template slot-scope="scope">
            {{ scope.row.payPrice ? (scope.row.orderAmount / 100).toFixed(2) : 0 }}
          </template>
        </el-table-column>
        <el-table-column prop="royaltyMoney" align="center" label="分销佣金">
          <template slot-scope="scope">
            {{
              scope.row.commissionAmount
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="transferAmount"
          align="center"
          label="分销类型"
        >
          <template slot-scope="scope">
            {{
              scope.row.level == 1 ? '一级分销' :  scope.row.level == 2 ? '二级分销' : ''
            }}
          </template>
        </el-table-column>
        <el-table-column prop="distributor" align="center" label="分销员姓名" />
        <el-table-column prop="distributorPhone" align="center" label="分销员电话" />
        <el-table-column prop="payTime" align="center" show-overflow-tooltip label="付款时间">
          <template slot-scope="scope">
            {{ scope.row.payTime | getDataTimeSec }}
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" show-overflow-tooltip label="付款人姓名" />
        <el-table-column prop="phone" align="center" label="联系方式" />
        <el-table-column prop="orderStatus" align="center" label="佣金状态">
          <template slot-scope="scope">
            <span :style="{ color: scope.row.state == 0 ? '#ff0a0a' :'#51CDCB' }">{{
              scope.row.state == 1 ? "已结算" : "待结算"
            }}</span>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { getDataTimeSec } from "@/utils";
import { selectCompanyTransferList, updateCompanyTransferState } from "@/api/distribution";
export default {
  name: "accountTransfer",
  components: {
    commonTable
  },
  data() {
    return {
      options: [
        { value: "", label: "全部订单" },
        { value: "0", label: "待入账" },
        { value: "1", label: "已结算" },
      ],
      radioValue: "",
      tableData: [],
      ordersNo:"",
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      pageSize2: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      moneyBack: 0,
      noMoneyBack: 0,
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
    formatMoney(val) {
      return val ? (val / 100).toFixed(2) : 0;
    },
  },
  created() {
    this.selectCompanyTransferList();
  },
  methods: {
    async selectCompanyTransferList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        state: this.radioValue,
        ordersNo:this.ordersNo
      };
      try {
        this.loading = true;
        const result = await selectCompanyTransferList(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
          moneyBack,
          noMoneyBack,
        } = result?.data;
        this.tableData = list;
        this.total = total;
        this.moneyBack = moneyBack;
        this.noMoneyBack = noMoneyBack;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
        console.log(error);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectCompanyTransferList()
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.selectCompanyTransferList()
    },
   async changeState(val){
     try {
       await updateCompanyTransferState({transferId:val.transferId});
       this.selectCompanyTransferList();
     } catch (error) {
       console.log(error)
     }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
